import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState } from "react";
import { useFormContext } from "../context/Context";
import { Alert } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MakePayment = () => {
  const { accountNumber, phone, totalAmount } = useFormContext();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const makeStudentPayment = async () => {
    try {
      const formData = new FormData();
      formData.append("account_number", accountNumber);
      formData.append("phone_number", phone);
      formData.append("amount", totalAmount);

      await axios.post(
        "https://mzazilink.com/e-wallet/api/v1/ussd/web/request_stk_push/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  React.useEffect(() => {
    // Open the dialog when both success and totalAmount conditions are true
    if (success && totalAmount) {
      setOpen(true);
    }
  }, [success, totalAmount]);

  const iconStyle = {
    fontSize: "5rem",
    verticalAlign: "middle",
  };

  return (
    <div className="makePayment_container">
      {totalAmount ? (
        success ? (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <Alert icon={false}>
              <DialogTitle
                sx={{ m: 0, p: 2, textAlign: "center" }}
                id="customized-dialog-title"
              >
                <CheckCircleOutlineIcon style={iconStyle} />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogTitle
                sx={{ m: 0, p: 2, textAlign: "center" }}
                id="customized-dialog-title"
              >
                Payment Initiated Successfully
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom style={{ color: "black" }}>
                  M-Pesa pop-up menu has been sent to your phone. Please
                  complete the payment. If you did not get the pop-up M-Pesa
                  menu, you can manually complete the payment by:
                </Typography>
                <Typography style={{ color: "black" }} gutterBottom>
                  Account number: <strong>{accountNumber}</strong>
                </Typography>
                <Typography style={{ color: "black" }} gutterBottom>
                  Paybill: <strong>827774</strong>
                </Typography>
                <Typography style={{ color: "black" }} gutterBottom>
                  Total Amount: <strong>Ksh.{totalAmount}</strong>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Alert>
          </BootstrapDialog>
        ) : (
          <button
            className="button-style"
            onClick={makeStudentPayment}
            variant="outlined"
          >
            Make Payment
          </button>
        )
      ) : null}
    </div>
  );
};

export default MakePayment;
