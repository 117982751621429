import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/Context";
import axiosInstance from "../../utils/Axios";
import CircularProgress from "@mui/material/CircularProgress";

const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    selectedSchoolPrefix,
    setSelectedPaymentTypeLetter,
    selectedPaymentOption,
    setSelectedPaymentOption,
    selectedStudentData,
    paymentData,
    setPaymentData,
  } = useFormContext();
  useEffect(() => {
    const getPaymentData = async () => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append(
          "school_prefix",
          selectedSchoolPrefix || selectedStudentData?.school_prefix
        );

        const response = await axiosInstance.post(
          "/get_school_menu_list/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setPaymentData(response?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getPaymentData();
  }, [
    selectedSchoolPrefix,
    selectedStudentData?.school_prefix,
    setPaymentData,
  ]);

  const handleChange = (event) => {
    const selectedPaymentLetter = event.target.value;
    setSelectedPaymentOption(selectedPaymentLetter);
    setSelectedPaymentTypeLetter(selectedPaymentLetter);
  };

  return (
    <div className="payment_container">
      {isLoading ? (
        <main style={{ width: "100%", alignItems: "center" }}>
          <CircularProgress />
        </main>
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="payment-options-label">Payment Options</InputLabel>
            <Select
              labelId="payment-options-label"
              id="payment-options"
              value={selectedPaymentOption}
              label="Select payment type *"
              onChange={handleChange}
            >
              {Array.isArray(paymentData) &&
                paymentData.map((pData, index) => (
                  <MenuItem key={index} value={pData?.transaction_type_letter}>
                    {pData?.transaction_type_title.toUpperCase()}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default Payment;
