import { TextField, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormContext } from "../context/Context";
import axiosInstance from "../../utils/Axios";
import CircularProgress from "@mui/material/CircularProgress";

const School = () => {
  const {
    setSelectedSchoolPrefix,
    setSelectedSchoolCode,
    schoolInputValue,
    setDisabledSchoolInput,
    setStudentComp,
    setStudent,
    isFormOneAdmission,
    setIsFormOneAdmission,
    setPaymentComp,
    setSchoolComp,
    setAmountComp,
    setMakePaymentComp,
    setShowAlert,
    setSelectedStudentData,
    setAmount,
    setSelectedPaymentOption,
    setTotalAmount,
  } = useFormContext();
  const [data, setData] = useState([]);
  const [school, setSchool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const response = await axiosInstance.get("/all_schools/");
        const fetchedData = response?.data?.data;
        const sortedData = fetchedData.sort((a, b) =>
          a.school_name.localeCompare(b.school_name)
        );
        setData(sortedData);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsFetching(false);
      }
    };
    fetchData();
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    setSchool(newValue);
    if (newValue) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const handleEditClick = () => {
    setDisabledInput(false);
    setDisabledSchoolInput(false);
    setStudentComp(false);
    setStudent([]);
  };

  const handleSaveClick = () => {
    if (school) {
      const selectedSchool = data.find((item) => item.school_name === school);
      setSelectedSchoolPrefix(selectedSchool?.school_prefix);
      setSelectedSchoolCode(selectedSchool?.school_code);
      setDisabledInput(true);
      setDisabledBtn(true);
      setDisabledSchoolInput(true);
      if (isFormOneAdmission) {
        setStudentComp(false);
        setPaymentComp(true);
      } else {
        setStudentComp(true);
      }
    }
  };

  const handleIsFormOneAdmissionClick = () => {
    setSchoolComp(true);
    setAmountComp(false);
    setShowAlert(true);
    setPaymentComp(false);
    setSelectedStudentData([]);
    setMakePaymentComp(false);
    setAmount("");
    setSelectedPaymentOption("");
    setTotalAmount("0");
    setIsFormOneAdmission(true);
    setStudentComp(false);
  };

  return (
    <div className="school_component">
      {isFetching ? (
        <main style={{ width: "100%", alignItems: "center" }}>
          <CircularProgress />
        </main>
      ) : (
        <>
          {!isFormOneAdmission && (
            <div
              onClick={handleIsFormOneAdmissionClick}
              className="studentPhone_container_btn"
            >
              <p> FORM ONE ADMISSION ?</p>
              <div className="studentPhone_container_btn_line"></div>
            </div>
          )}
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={data.map((item) => item.school_name)}
            value={school}
            onChange={handleAutocompleteChange}
            inputValue={schoolInputValue}
            style={{ minWidth: "200px", width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                label="Select School *"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  disabled: isLoading || disabledInput, // Disable input when loading or if editing is disabled
                  endAdornment: disabledInput && ( // Show edit button only when editing is enabled
                    <InputAdornment onClick={handleEditClick} position="end">
                      <div className="phone_container_p">
                        <p>Edit</p>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </>
      )}
      {!disabledInput && (
        <button
          className={"button-style"}
          onClick={handleSaveClick}
          disabled={!school || isLoading || disabledBtn}
        >
          Continue
        </button>
      )}
    </div>
  );
};

export default School;
