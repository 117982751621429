import { TextField, InputAdornment, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/Context";
import axiosInstance from "../../utils/Axios";

const Phone = () => {
  const {
    phone,
    setPhone,
    setPhoneData,
    showAlert,
    setShowAlert,
    setStudentPhoneComp,
    setStudentData,
    setStudent,
    setPaymentComp,
    setSchoolComp,
    setAmountComp,
    setStudentComp,
    setIsAvailable,
    setPhoneLoading,
    setMakePaymentComp,
  } = useFormContext();
  const [error, setError] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [showAlertForOneMinute, setShowAlertForOneMinute] = useState(false);

  const fetchData = async () => {
    setPhoneLoading(true);
    try {
      const formData = new FormData();
      formData.append("phone_number", phone);
      const response = await axiosInstance.post("/check_phone/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setPhoneData(response?.data?.data);
      // Check if the API response returns an empty array, and if so, show the alert
      setShowAlert(response?.data?.data?.length === 0);
    } catch (error) {
      console.log(error);
    } finally {
      setPhoneLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhone(inputPhone);
    setError(!inputPhone.startsWith("0"));
    setDisabledBtn(inputPhone.length !== 10 || !inputPhone.startsWith("0"));
  };

  const handleEditClick = () => {
    setDisabledInput(false);
    setShowAlert(false);
    setPhoneData([]);
    setStudentPhoneComp(false);
    setStudentData([]);
    setStudent("");
    setPaymentComp(false);
    setSchoolComp(false);
    setAmountComp(false);
    setStudentComp(false);
    setIsAvailable(false);
    setMakePaymentComp(false);
  };

  const handlePhoneClick = () => {
    fetchData();
    setDisabledInput(true);
    setDisabledBtn(true);

    if (!showAlert) {
      setStudentPhoneComp(true);
    }
  };

  // Function to hide the alert after 1 minute
  useEffect(() => {
    if (showAlert) {
      setShowAlertForOneMinute(true);
      const timer = setTimeout(() => {
        setShowAlertForOneMinute(false);
      }, 30000); // 60000 milliseconds = 30 sec
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <div className="phone_container">
      <TextField
        autoFocus
        type="tel"
        inputmode="tel"
        className="form_input"
        style={{ minWidth: "200px", width: "100%" }}
        id="outlined-basic"
        label="Phone Number"
        variant="outlined"
        size="large"
        value={phone}
        onChange={handlePhoneChange}
        error={error}
        helperText={error && "Phone number must be a safaricom line"}
        disabled={disabledInput}
        InputProps={{
          endAdornment: disabledInput && (
            <InputAdornment onClick={handleEditClick} position="end">
              <div className="phone_container_p">
                <p>Edit</p>
              </div>
            </InputAdornment>
          ),
        }}
      />
      {!disabledInput && (
        <button
          className={`button-style${disabledBtn ? " disabled" : ""}`}
          disabled={disabledBtn}
          onClick={handlePhoneClick}
        >
          Continue
        </button>
      )}
      {/* Show the Alert only when showAlert is true */}
      {showAlert && showAlertForOneMinute && (
        <Alert
          style={{ marginTop: "20px", marginBottom: "20px" }}
          icon={false}
          severity="warning"
        >
          There was no student linked to {phone}. Select your child's school to
          continue
        </Alert>
      )}
    </div>
  );
};

export default Phone;
