import {
  TextField,
  InputAdornment,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/Context";
import axiosInstance from "../../utils/Axios";
import CircularProgress from "@mui/material/CircularProgress";

const Student = () => {
  const {
    student,
    setStudent,
    setStudentData,
    selectedSchoolPrefix,
    selectedStudentData,
    studentData,
    setPaymentComp,
  } = useFormContext();
  const [error, setError] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [selectedStudentNumber, setSelectedStudentNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getStudent = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        "school_prefix",
        selectedSchoolPrefix || selectedStudentData?.school_prefix
      );
      formData.append("student_number", student);

      const response = await axiosInstance.post(
        "/search_student_by_school/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const studentData = response?.data?.data || [];
      setError(studentData.length === 0);
      setStudentData(studentData);
    } catch (error) {
      console.log(error);
      setError(true);
      setStudentData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStudentChange = (e) => {
    const inputStudent = e.target.value.replace(/\D/g, "");
    setStudent(inputStudent);
  };

  const handleEditClick = () => {
    setDisabledInput(false);
    setPaymentComp(false);
  };

  const handleStudentClick = async () => {
    if (student.length === 0) {
      setError(true);
      return;
    }

    try {
      await getStudent();
      setDisabledInput(true);
      setDisabledBtn(true);

      // Check if any student data is available
      if (studentData.length > 0) {
        // Assuming you want to show data of the first student in the array
        const selectedStudent = studentData.find(
          (student) => student.student_number === selectedStudentNumber
        );
        if (selectedStudent) {
          setStudent(selectedStudent);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRadioClick = () => {
    setPaymentComp(!error);
  };

  // New function to handle the radio button selection
  const handleRadioChange = (event) => {
    setSelectedStudentNumber(event.target.value);
  };

  useEffect(() => {
    setDisabledBtn(student.length === 0);
    if (error) {
      setDisabledInput(false);
      setDisabledBtn(false);
    }
  }, [student, error]);

  return (
    <div className="student_container">
      {isLoading ? (
        <main style={{ width: "100%", alignItems: "center" }}>
          <CircularProgress />
        </main>
      ) : (
        <>
          <TextField
            autoFocus
            type="tel"
            inputmode="tel"
            className="form_input"
            style={{ minWidth: "200px", width: "100%" }}
            id="outlined-basic"
            label="Enter admission no. *"
            variant="outlined"
            value={student}
            onChange={handleStudentChange}
            disabled={disabledInput}
            helperText={
              error &&
              `There was no student with that admission in ${selectedSchoolPrefix}`
            }
            error={error}
            InputProps={{
              endAdornment: disabledInput && (
                <InputAdornment onClick={handleEditClick} position="end">
                  <div className="phone_container_p">
                    <p>Edit</p>
                  </div>
                </InputAdornment>
              ),
            }}
          />

          {!disabledInput && (
            <button
              className={`button-style${disabledBtn ? " disabled" : ""}`}
              disabled={disabledBtn}
              onClick={handleStudentClick}
            >
              Continue
            </button>
          )}

          <Alert style={{ textAlign: "start" }} icon={false} severity="info">
            {Array.isArray(studentData) &&
              studentData.length > 0 &&
              studentData.map((student, idx) => (
                <p key={idx}>
                  <strong>
                    (1) Student found <br />
                    <RadioGroup
                      style={{ padding: "5px " }}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="student-radio-group"
                      value={selectedStudentNumber}
                      onChange={handleRadioChange}
                      onClick={handleRadioClick}
                    >
                      <FormControlLabel
                        value={student.student_number}
                        control={<Radio />}
                        label={student?.student_name}
                      />
                    </RadioGroup>
                  </strong>
                </p>
              ))}
          </Alert>
        </>
      )}
    </div>
  );
};

export default Student;
