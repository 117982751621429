import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, FormControl } from "@mui/material";
import { useFormContext } from "../context/Context";
import CircularProgress from "@mui/material/CircularProgress";

const StudentPhone = () => {
  const {
    phoneData,
    selectedSchoolPrefix,
    setSelectedStudentData,
    selectedStudentData,
    setPayment,
    setIsAvailable,
    setSchoolComp,
    schoolComp,
    setStudentData,
    setStudent,
    setStudentComp,
    setPaymentComp,
    setAmountComp,
    setMakePaymentComp,
    setAmount,
    setSelectedPaymentOption,
    setTotalAmount,
    phoneLoading,
    setShowAlert,
    isFormOneAdmission,
    setIsFormOneAdmission,
  } = useFormContext();

  const handleShowSchoolClick = () => {
    setSchoolComp(true);
    setAmountComp(false);
    setShowAlert(true);
    setPaymentComp(false);
    setSelectedStudentData([]);
    setMakePaymentComp(false);
    setAmount("");
    setSelectedPaymentOption("");
    setTotalAmount("0");
  };

  const handleIsFormOneAdmissionClick = () => {
    setSchoolComp(true);
    setAmountComp(false);
    setShowAlert(true);
    setPaymentComp(false);
    setSelectedStudentData([]);
    setMakePaymentComp(false);
    setAmount("");
    setSelectedPaymentOption("");
    setTotalAmount("0");
    setIsFormOneAdmission(true);
    setStudentComp(false);
  };
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = phoneData.find(
      (pData) => pData.student_name === selectedValue
    );

    // Check if the selected student is already the current selection
    if (selectedObject.student_name === selectedSchoolPrefix) {
      // If it is the current selection, clear the selection
      setSelectedStudentData([]);
      setPayment(false);
      setIsAvailable(false);
      setPaymentComp(false);
      setMakePaymentComp(false);
    } else {
      // If it is not the current selection, update the selection
      setSelectedStudentData(selectedObject);
      setPayment(true);
      setIsAvailable(true);
      setStudentComp(false);
      setSchoolComp(false);
      setStudentData([]);
      setStudent("");
    }
  };

  useEffect(() => {
    if (schoolComp) {
      setSelectedStudentData([]);
    }
  }, [schoolComp, setSelectedStudentData]);
  return (
    <div className="studentPhone_container">
      {phoneLoading ? (
        <main style={{ width: "100%", alignItems: "center" }}>
          <CircularProgress />
        </main>
      ) : (
        <>
          <div className="container_header">
            <p>Select student *</p>
          </div>
          {phoneData && phoneData.length > 0 && (
            <FormControl style={{ width: "100%" }}>
              {phoneData.map((pData, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedStudentData === pData}
                      onChange={handleChange}
                      value={pData.student_name}
                    />
                  }
                  label={
                    <div className="labels">
                      <strong>{pData.student_name}</strong>
                      <br />
                      <p>{pData.school_prefix}</p>
                    </div>
                  }
                />
              ))}
            </FormControl>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <div
              onClick={handleShowSchoolClick}
              className="studentPhone_container_btn"
            >
              <p>Pay for another Student ?</p>
              <div className="studentPhone_container_btn_line"></div>
            </div>
            {!isFormOneAdmission && (
              <div
                onClick={handleIsFormOneAdmissionClick}
                className="studentPhone_container_btn"
              >
                <p> FORM ONE ADMISSION ?</p>
                <div className="studentPhone_container_btn_line"></div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StudentPhone;
