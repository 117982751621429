import "./App.css";
import Form from "./pages/forms/Form";
// import backgroundImage from "./assets/back.jpg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Navbar from "./components/Navbar";
import { FormProvider } from "./pages/context/Context";
// import AlertMessage from "./components/Alert";
import playStoreApp from "./assets/ussd.png";

function App() {
  return (
    <div className="app">
      <FormProvider>
        <Navbar />

        <div className="app_background_img">
          {/* <img src={backgroundImage} alt="background" /> */}
        </div>
        <div className="app_content">
          {/* <AlertMessage /> */}
          <Form />
          <div className="app_content_whatsApp">
            <a target="blank" href="https://wa.me/+254728110017">
              <WhatsAppIcon
                style={{ fontSize: "2rem" }}
                className="app_content_icon"
              />
            </a>
          </div>
          <div className="mobile_app">
            <a href="https://play.google.com/store/apps/details?id=com.mzazilink.mzazipay&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
        </div>
        {/* <Banner /> */}
      </FormProvider>
    </div>
  );
}

export default App;
