import React, { createContext, useContext, useEffect, useState } from "react";

const FormContext = createContext();

export function useFormContext() {
  return useContext(FormContext);
}

export function FormProvider({ children }) {
  const [phone, setPhone] = useState("");
  const [phoneData, setPhoneData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedSchoolPrefix, setSelectedSchoolPrefix] = useState(null);
  const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);
  const [student, setStudent] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [selectedPaymentTypeLetter, setSelectedPaymentTypeLetter] =
    useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [selectedStudentData, setSelectedStudentData] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [payment, setPayment] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [disabledSchoolInput, setDisabledSchoolInput] = useState(true);
  const [amountComp, setAmountComp] = useState(false);
  const [studentPhoneComp, setStudentPhoneComp] = useState(false);
  const [schoolComp, setSchoolComp] = useState(false);
  const [studentComp, setStudentComp] = useState(false);
  const [paymentComp, setPaymentComp] = useState(false);
  const [makePaymentComp, setMakePaymentComp] = useState(false);
  const [amount, setAmount] = useState("");
  const [isFormOneAdmission, setIsFormOneAdmission] = useState(false);

  // loadings
  const [phoneLoading, setPhoneLoading] = useState(false);
  useEffect(() => {
    let newAccountNumber = "";

    if (isFormOneAdmission) {
      // Use the selectedSchoolCode, selectedPaymentOption, and phone for accountNumber
      newAccountNumber =
        (selectedSchoolCode || selectedStudentData?.school?.school_code) +
        selectedPaymentOption +
        phone;
    } else {
      // Use the selectedSchoolCode, student, and selectedPaymentOption for accountNumber
      newAccountNumber =
        (selectedSchoolCode || selectedStudentData?.school?.school_code) +
        (student || selectedStudentData?.student_number) +
        selectedPaymentOption;
    }

    setAccountNumber(newAccountNumber);
  }, [
    isFormOneAdmission,
    selectedStudentData,
    selectedPaymentOption,
    student,
    selectedSchoolCode,
    payment,
    phone,
  ]);

  useEffect(() => {
    // Update the conditions to set amountComp to true
    if (selectedPaymentTypeLetter && selectedPaymentOption) {
      setAmountComp(true);
    } else {
      setAmountComp(false);
    }
  }, [selectedPaymentTypeLetter, selectedPaymentOption]);

  return (
    <FormContext.Provider
      value={{
        phone,
        setPhone,
        phoneData,
        setPhoneData,
        showAlert,
        setShowAlert,
        selectedSchoolPrefix,
        setSelectedSchoolPrefix,
        selectedSchoolCode,
        setSelectedSchoolCode,
        student,
        setStudent,
        studentData,
        setStudentData,
        paymentData,
        setPaymentData,
        selectedPaymentTypeLetter,
        setSelectedPaymentTypeLetter,
        selectedPaymentOption,
        setSelectedPaymentOption,
        accountNumber,
        selectedStudentData,
        setSelectedStudentData,
        isAvailable,
        setIsAvailable,
        payment,
        setPayment,
        totalAmount,
        setTotalAmount,
        disabledSchoolInput,
        setDisabledSchoolInput,
        amountComp,
        setAmountComp,
        studentPhoneComp,
        setStudentPhoneComp,
        schoolComp,
        setSchoolComp,
        studentComp,
        setStudentComp,
        paymentComp,
        setPaymentComp,
        makePaymentComp,
        setMakePaymentComp,
        amount,
        setAmount,
        phoneLoading,
        setPhoneLoading,
        isFormOneAdmission,
        setIsFormOneAdmission,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
