import React from "react";
import "./Form.css";
import School from "./School";
import Phone from "./Phone";
import Student from "./Student";
import Payment from "./Payment";
import Amount from "./Amount";
import MakePayment from "./MakePayment";
import StudentPhone from "./StudentPhone";
import { Alert } from "@mui/material";
import { useFormContext } from "../context/Context";

const Form = () => {
  const {
    studentPhoneComp,
    showAlert,
    schoolComp,
    isAvailable,
    studentData,
    studentComp,
    paymentComp,
    amountComp,
    makePaymentComp,
    student,
    selectedStudentData,
    phoneData,
    phone,
  } = useFormContext();

  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <div className="form">
      <div className="form_inputs">
        <div className="form_inputs_text">
          <strong>Welcome To Mzazilink</strong>
          <p>
            To make payment for your child enter phone number below to continue
          </p>
        </div>

        {/* Implement the Phone component */}
        <Phone />
        {studentPhoneComp && !showAlert && <StudentPhone />}
        {/* School */}
        {showAlert || schoolComp || (isAvailable && !studentData) ? (
          <School />
        ) : null}
        {/* Student */}
        {(showAlert && studentComp) ||
        (isAvailable && studentComp) ||
        (schoolComp && studentComp) ? (
          <Student />
        ) : null}
        {/* Type of payment intent */}
        {(showAlert && paymentComp) ||
        (isAvailable && selectedStudentData.student_name) ||
        (isAvailable && studentData && student) ||
        (schoolComp && paymentComp) ? (
          <Payment />
        ) : null}
        {/* Amount component */}
        {amountComp && <Amount />}
        {/* Make payment */}
        {makePaymentComp && <MakePayment />}
        {phoneData && phone ? (
          <Alert
            style={{
              fontSize: "20px",
              textAlign: "center",
              marginTop: "30px",
              color: "red",
              cursor: "pointer",
            }}
            onClick={reloadPage}
            icon={false}
            variant="outlined"
            severity="error"
          >
            Reset
          </Alert>
        ) : null}
      </div>
    </div>
  );
};

export default Form;
