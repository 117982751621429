import { Alert, AlertTitle, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/Context";

const Amount = () => {
  const { totalAmount, setTotalAmount, setMakePaymentComp, amount, setAmount } =
    useFormContext();
  const [charges, setCharges] = useState("");
  const [disabledAmountInput, setDisabledAmountInput] = useState(false);
  const [disabledAmountBtn, setDisabledAmountBtn] = useState(true);
  const getWithdrawalCharges = (amount) => {
    if (amount < 100) {
      return 10;
    } else if (amount <= 2500) {
      return 30;
    } else if (amount <= 5000) {
      return 50;
    } else if (amount <= 7500) {
      return 60;
    } else if (amount <= 10000) {
      return 80;
    } else {
      return 100;
    }
  };

  const handleAmountChange = (e) => {
    const inputAmount = e.target.value.replace(/\D/g, "");
    setAmount(inputAmount);

    const withdrawalCharges = getWithdrawalCharges(parseInt(inputAmount));
    const totalAmountWithCharges = parseInt(inputAmount) + withdrawalCharges;
    setTotalAmount(totalAmountWithCharges);

    setDisabledAmountBtn(inputAmount.length === 0);
  };

  useEffect(() => {
    setCharges(totalAmount - amount);
  }, [totalAmount, amount]);

  const handleEditClick = () => {
    setDisabledAmountInput(false);
    setMakePaymentComp(false);
  };

  const handleAmountClick = () => {
    setDisabledAmountInput(true);
    setMakePaymentComp(true);
  };

  return (
    <div className="amount_container">
      <div className="container_header">{/* <p>Enter the amount *</p> */}</div>
      <TextField
        autoFocus
        type="tel"
        inputmode="tel"
        className="form_input"
        style={{ minWidth: "200px", width: "100%" }}
        id="outlined-basic"
        label="Enter the amount *"
        variant="outlined"
        size="large"
        value={amount}
        onChange={handleAmountChange}
        disabled={disabledAmountInput}
        autoComplete="off"
        InputProps={{
          endAdornment: disabledAmountInput && (
            <InputAdornment onClick={handleEditClick} position="end">
              <div className="phone_container_p">
                <p>Edit</p>
              </div>
            </InputAdornment>
          ),
        }}
      />
      {!disabledAmountInput && (
        <button
          className={`button-style${disabledAmountBtn ? " disabled" : ""}`}
          disabled={disabledAmountBtn}
          onClick={handleAmountClick}
        >
          Continue
        </button>
      )}

      <Alert
        style={{
          marginTop: "5px",
          textAlign: "start",
        }}
        icon={false}
        severity="info"
      >
        <AlertTitle>Withdrawal charges: ksh.{charges}</AlertTitle>
        Total Amount: ksh.<strong>{totalAmount}</strong>
      </Alert>
    </div>
  );
};

export default Amount;
