import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar_links">
        <p>Mzazilink</p>
        <div className="phone_section">
          <h4>
            USSD <strong> *483*8383#</strong>
          </h4>
          <h5>
            Helpline <strong> 0728110017</strong>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
